import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Layout from "../components/Layout";
import PageIntroduction from "../components/PageIntroduction";
import Seo from "../components/seo";
import { Title as SectionTitle } from "../styles";

const AboutUs = () => {
  return (
    <Layout>
      <Seo
        title="About Us"
        description={
          "Our highly accomplished board of Trustees contribute expertise from across the sectors of education, industry and finance. Our Trustees are responsible for the overall control and strategic direction of The Children’s Educational Trust."
        }
      />
      <Container>
        <OurTrustees />
        <OurPartners />
      </Container>
    </Layout>
  );
};

const OurTrustees = () => {
  return (
    <>
      <PageIntroduction
        title="Our Trustees"
        body="Our highly accomplished board of Trustees contribute expertise from across the sectors of education, industry and finance. Our Trustees are responsible for the overall control and strategic direction of The Children’s Educational Trust."
      />
      <Row className="mt-3">
        <Card
          title="Hirji Patel"
          body="
        Hirji Patel graduated from Queen Mary college, University of London,
        specialising in Avionics. His expertise led to him holding senior
        positions within many corporations including GEC Avionics and British
        Aerospace before deciding to turn “freelance” and subsequently being
        contracted by companies such as Reuters and BT. In 1994, having
        identified a market opportunity, he launched a highly acclaimed
        specialist laptop brand in the UK which in 2000 subsequently went
        international. Hirji has vast experience with charity work having
        previously served as a Treasurer and Holding Trustee for a prominent
        charity in London. He is a keen supporter of charities and continues to
        be actively involved in a number of charities in India, Kenya and the
        UK.
      "
        />
        <Card
          title="Andrew K Lovett"
          body="Kevin Lovett is a qualified Banker and a part qualified Accountant. He has worked in finance for almost 45 years both in International Banking and more recently works with Commercial firms in a number of different sectors, including most significantly, education. Through his links with Round Table and 41 Club he is a dedicated supporter of charitable projects."
        />
        <Card
          title="Nigel Woolf"
          body="Nigel Woolf has over 20 years’ experience working with the education sector. After graduating from Manchester with a degree in Accounting & Finance, he went to work for the largest independent leasing company in Europe and within two years was appointed to the management board at the age of 25.
In 1994, Nigel set up his own consultancy business and currently sits on the Executive Board of a number of organisations. Nigel has a long standing relationship with a number of charities has been one of our Trustees since the charity began."
        />
      </Row>
    </>
  );
};

const OurPartners = () => {
  return (
    <>
      <SectionTitle>Our Partners</SectionTitle>
      <Row className="mt-2 mb-5">
        <Card
          title="Funding 4 Education"
          body="Through innovative and bespoke financial solutions tailored to meet the exacting requirements of any school budget, we have been and continue to be able help schools and communities."
          image={
            <StaticImage
              src={"../images/funding4education_logo.png"}
              style={StaticImageStyle}
            />
          }
        />
        <Card
          title="Technology Rentals"
          body="Technology Rentals is collaborating with us to provide valuable end of lease equipment which can be utilised in schools overseas."
          image={
            <StaticImage
              src={"../images/technology-rentals_logo.jpeg"}
              style={StaticImageStyle}
            />
          }
        />
        <Card
          title="Compulease"
          body="Compulease is the UK’s largest IT leasing provider and enables businesses, schools, colleges and universities to obtain IT equipment and keep in touch with technology as it develops, in the most cost efficient way."
          image={
            <StaticImage
              src={"../images/compulease_logo.png"}
              style={StaticImageStyle}
            />
          }
        />
      </Row>
    </>
  );
};

const Card = ({ title, body, image }) => {
  return (
    <Col xs="12" lg="4" className="mb-5 mb-lg-0">
      <Title>{title}</Title>
      <Body>{body}</Body>
      <StyledImage>{image}</StyledImage>
    </Col>
  );
};

const Title = styled.h4`
  color: ${({ theme: { colors } }) => colors.purpleSecondary};
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Body = styled.p`
  text-align: center;
  @media (min-width: 992px) {
    min-height: 100px;
  }
`;

const StyledImage = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 992px) {
    margin-top: 2rem;
  }
`;

const StaticImageStyle = {
  // width: "70%",
  width: "270px",
};
export default AboutUs;
